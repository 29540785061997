////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(244, 244, 244, 1);
}
.bg-secondaryColor {
  background-color: rgba(189, 189, 189, 1);
}
.bg-thirdColor {
  background-color: rgba(189, 189, 189, 1);
}
.bg-fourthColor {
  background-color: rgba(210, 32, 15, 1);
}
.bg-fifthColor {
  background-color: rgba(138, 21, 9, 1);
}
.bg-sixthColor {
  background-color: rgba(244, 244, 244, 1);
}
.bg-seventhColor {
  background-color: rgba(244, 244, 244, 1);
}
